import Rails from "@rails/ujs"
import "@hotwired/turbo-rails"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import "controllers"
require("jquery")

Rails.start()
ActiveStorage.start()

document.addEventListener("DOMContentLoaded", () => {
  document.querySelectorAll('.submit-btn').forEach(button => {
    button.addEventListener('click', () => {
      button.disabled = true;
      // Optional: Add a class to indicate loading state
      // button.classList.add('is-loading');
    });
  });
});

// Existing code for sticky navbar
window.onscroll = () => {  
  var header_navbar = document.querySelector(".navbar-area");  
  var sticky = header_navbar.offsetTop;

  if (window.pageYOffset > sticky) {  
    header_navbar.classList.add("sticky");  
  } else {  
    header_navbar.classList.remove("sticky");  
  }
};